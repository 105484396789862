import React from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';

const TableOfContents = ({ isOpen, pagesList }) => (
    <aside id="toc-open" className={`bracket__fixed toc backdrop backdrop--left${(isOpen) ? ' _is-open' : ''}`}>
        <a href="#toc-closed" className="toc__close" title="Close table of contents">
            <Icon id="cross" />
        </a>
        <ul className="toc-nav">
            { pagesList.map((page, key) => (
                <li className="toc-nav__item" key={key}>
                    <Link to={page.href} activeClassName="_is-active" className="toc-nav__link text-small-heading" title={page.title}>{page.title}</Link>
                </li>
            ))}
        </ul>
    </aside>
);

TableOfContents.defaultProps = {
    pagesList: [],
    isOpen: false
};

export default TableOfContents;
