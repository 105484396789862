import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const useSiteMetadata = () => {
    const location = useLocation();
    const { site } = useStaticQuery(
        graphql`
        query {
          site {
            siteMetadata {
              title
              description
              siteName
              twitterHandle
              siteURL
            }
          }
        }
      `,
    );
    return {
        ...site.siteMetadata,
        currentUrl: `${site.siteMetadata.siteURL}${location.pathname}`
    };
};

export default useSiteMetadata;
