import React from 'react';

import Icon from '@components/_ui/Icon';

const QuoteBlock = ({
    quote, authorAvatar, authorAvatarAlt, authorName, authorPosition
}) => (
    <div className="quote-block">
        <Icon id="quote" className="quote-block__icon" />
        <div className="quote-block__content">
            <blockquote className="text-quote" dangerouslySetInnerHTML={{ __html: quote }} />
            <div className="badge">
                <img src={authorAvatar} alt={authorAvatarAlt} className="avatar" />
                <div className="badge__content">
                    <span className="text-subheading">{authorName}</span><br />
                    <span className="text-metadata">{authorPosition}</span>
                </div>
            </div>
        </div>
    </div>
);

QuoteBlock.defaultProps = {
    quote: 'Our aim is to provide a quality of product and service that consistently meets our customer’s requirements. To constantly improve and enhance our product range and operating procedures whilst being recognised as an established supplier of first class garden structures and accessories.',
    authorAvatar: '/img/ade-avatar.jpg',
    authorAvatarAlt: 'Managing director Adrian Valentine, leaning against a pergola post',
    authorName: 'Adrian Valentine',
    authorPosition: 'Managing Director',
};

export default QuoteBlock;
