import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({
    title,
    description,
    canonical,
    currentUrl,
    siteName,
    image,

    twitterCard,
    twitterHandle,

    themeColor,

    /** These arent required but we generate them by the manifest plugin */
    manifest,
    icon16,
    icon32,
    icon180,
    icon192,
    icon270,

    articlePublisher,
    articleModifiedTime,

}) => (
    <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        {canonical && <link rel="canonical" href={canonical} />}

        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />


        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={siteName} />
        <meta property="og:description" content={description || 'Perfect Pergolas provide a totally unique design service for all our UK made pergolas. Choose from our wide range of our made to order pergolas right here!'} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content={siteName} />
        {articlePublisher && <meta property="article:publisher" content={articlePublisher} />}
        {articleModifiedTime && <meta property="article:modified_time" content={articleModifiedTime} />}
        <meta property="og:image" content={image} />
        {twitterCard && <meta name="twitter:card" content={twitterCard} />}
        {twitterHandle && <meta name="twitter:creator" content={twitterHandle} />}
        {twitterHandle && <meta name="twitter:site" content={twitterHandle} />}
        <meta name="twitter:image" content={image} />


        {manifest && <link rel="manifest" href={manifest} />}
        { icon16 && <link rel="icon" type="image/png" sizes="16x16" href={icon16} />}
        { icon32 && <link rel="icon" type="image/png" sizes="32x32" href={icon32} />}
        { icon180 && <link rel="apple-touch-icon" sizes="180x180" href={icon180} />}
        <meta name="apple-mobile-web-app-title" content={siteName} />
        <meta name="application-name" content={siteName} />
        <meta name="msapplication-TileColor" content={themeColor} />
        <meta name="theme-color" content={themeColor} />

        { icon32 && <link rel="icon" href={icon32} sizes="32x32" />}
        { icon192 && <link rel="icon" href={icon192} sizes="192x192" />}
        { icon180 && <link rel="apple-touch-icon-precomposed" href={icon180} />}
        { icon270 && <meta name="msapplication-TileImage" content={icon270} />}

    </Helmet>
);
SEO.defaultProps = {
    canonical: undefined,
    twitterCard: 'summary_large_image',
    twitterHandle: undefined,
    manifest: undefined,
    icon16: undefined,
    icon32: undefined,
    icon180: undefined,
    icon192: undefined,
    icon270: undefined,
    articlePublisher: undefined,
    articleModifiedTime: undefined,
};
SEO.propTypes = {
    /** Title and meta title for the site */
    title: PropTypes.string.isRequired,
    /** Description of the site */
    description: PropTypes.string.isRequired,
    /** Specific canonical url for the site otherwise it will default to the current url. */
    canonical: PropTypes.string,
    /** Current url of the site */
    currentUrl: PropTypes.string.isRequired,
    /** Site name */
    siteName: PropTypes.string.isRequired,
    /** Image to display. */
    image: PropTypes.string.isRequired,

    /** The type of card for twitter to display */
    twitterCard: PropTypes.string,
    /** Twitter handle of the company / site */
    twitterHandle: PropTypes.string,

    /** Hex string */
    themeColor: PropTypes.string.isRequired,

    /** Manifest file for the site - These arent required but we generate them by the manifest plugin */
    manifest: PropTypes.string,
    /** Url of icon that fits 16x16 - These arent required but we generate them by the manifest plugin */
    icon16: PropTypes.string,
    /** Url of icon that fits 32x32 - These arent required but we generate them by the manifest plugin */
    icon32: PropTypes.string,
    /** Url of icon that fits 180x180 - These arent required but we generate them by the manifest plugin */
    icon180: PropTypes.string,
    /** Url of icon that fits 192x192 - These arent required but we generate them by the manifest plugin */
    icon192: PropTypes.string,
    /** Url of icon that fits 270x270 - These arent required but we generate them by the manifest plugin */
    icon270: PropTypes.string,


    /** Article publisher name */
    articlePublisher: PropTypes.string,
    /** Article publisher time */
    articleModifiedTime: PropTypes.string,
};

export default SEO;
