import React from 'react';

const Icon = ({ id, className }) => (
    <svg viewBox="0 0 48 48" width="48" height="48" className={`icon ${className}`}>
        <use xlinkHref={`#icon-${id}`} />
    </svg>
);

Icon.defaultProps = {
    id: '',
    className: '',
};

export default Icon;
