import InformationLayout from "../../../../src/layouts/InformationLayout.jsx";
import indexData from "../../../../src/pages/pergola-planning/index.json";
import Alert from "../../../../src/components/_ui/Alert.jsx";
import Downloads from "../../../../src/components/_ui/Downloads.jsx";
import * as React from 'react';
export default {
  InformationLayout,
  indexData,
  Alert,
  Downloads,
  React
};