module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-8730326-34","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/templates/Generic.jsx","planning":"/opt/build/repo/src/layouts/InformationLayout.jsx","arbourAssembly":"/opt/build/repo/src/layouts/InformationLayout.jsx","pergolaAssembly":"/opt/build/repo/src/layouts/InformationLayout.jsx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Perfect Pergolas","short_name":"Perfect Pergolas","start_url":"/","background_color":"#f5f3ed","theme_color":"#006b34","display":"standalone","icon":"static/img/brand/icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"64855b5f101618c2caa8111fbb749493"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
