import React from 'react';
import { Link } from 'gatsby';

import Logo from '@components/_ui/Logo';

const Footer = () => (
    <footer className="footer">
        <div className="container wrapper">
            <div className="grid-x grid-margin-x grid-margin-y">
                <div className="cell medium-6 large-3">
                    <Link to="/" title="Home">
                        <Logo className="footer__logo" />
                    </Link>
                </div>
                <div className="cell medium-6 large-3 flow">
                    <h5 className="text-label">Contact Us</h5>
                    <div className="flow--small">
                        <address>
                            Perfect Pergolas Ltd, <br />
                            PO Box 283,<br />
                            Cirencester. GL7 9EY.
                        </address>
                        <a href="mailto:info@perfectpergolas.co.uk" rel="nofollow" className="text-link display--block" title="Contact Perfect Pergolas via email">info@perfectpergolas.co.uk</a>
                    </div>
                </div>
                <div className="cell medium-6 large-3 flow">
                    <h5 className="text-label">Connect with us</h5>
                    <div className="flow--xsmall">
                        <a href="https://www.facebook.com/PerfectPergolas" rel="noreferrer" target="_blank" className="text-paragraph display--block" title="Connect with Perfect Pergolas on Facebook">Facebook</a>
                        <a href="https://twitter.com/perfectpergolas" rel="noreferrer" target="_blank" className="text-paragraph display--block" title="Follow Perfect Pergolas on Twitter">Twitter</a>
                        <a href="http://pinterest.com/perfectpergolas" rel="noreferrer" target="_blank" className="text-paragraph display--block" title="Follow Perfect Pergolas on Pinterest">Pinterest</a>
                        <a href="https://www.linkedin.com/company/perfect-pergolas-ltd/" rel="noreferrer" target="_blank" className="text-paragraph display--block" title="Connect with Perfect Pergolas on LinkedIn">LinkedIn</a>
                        <a href="https://www.youtube.com/channel/UCrB0VZTeES7ieQGKyP2iILA" rel="noreferrer" target="_blank" className="text-paragraph display--block" title="Subscribe to the Perfect Pergolas Youtube channel">Youtube</a>
                    </div>
                </div>
                <div className="cell medium-6 large-3 flow">
                    <h5 className="text-label">Useful links</h5>
                    <div className="flow--xsmall">
                        <Link to="/news/" className="text-paragraph display--block" title="Read more about getting the most out of your Pergola, Arbour, and plants">Outdoor living</Link>
                        <Link to="/privacy/" className="text-paragraph display--block" title="View our Privacy policy">Privacy policy</Link>
                        <Link to="/pergola-planning/disclaimer/" className="text-paragraph display--block" title="View our disclaimer policy">Disclaimer</Link>
                        <Link to="/terms-conditions/" className="text-paragraph display--block" title="View our full Terms &amp; conditions">Terms &amp; conditions</Link>
                        <Link to="/support/" className="text-paragraph display--block" title="Support">Support</Link>
                        <Link to="/plants/" className="text-paragraph display--block" title="Read about plants we recommend growing on and around your Pergola or Arbour">Plants</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="wrapper--xsmall background-color--chalk">
            <div className="container">
                <small className="text-metadata">Company Registered in UK and Wales: No. 7246705</small>
            </div>
        </div>
    </footer>
);

Footer.defaultProps = {

};

export default Footer;
