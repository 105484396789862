import React from 'react';

import Icon from '@components/_ui/Icon';
import QuoteBlock from '@components/_ui/QuoteBlock';

const ContactCallout = () => (
    <div className="grid-x grid-margin-x grid-margin-y">
        <div className="cell medium-6 flow--large">
            <h2 className="combo-heading">
                <span className="text-subtitle">We are here to help</span>
                <span className="text-paragraph text-color--steelbook">Email us to see how we can help you to create your perfect pergola</span>
            </h2>
            <div className="flow">
                <a href="mailto:info@perfectpergolas.co.uk" className="block-link" title="Contact Perfect Pergolas via email">
                    <Icon id="mail" className="icon--green icon--medium block-link__icon" />
                    <span className="block-link__content text-link">info@perfectpergolas.co.uk</span>
                </a>
                <a href="https://maps.google.com" className="block-link text-link" rel="nofollow" title="Find Perfect Pergolas on Google Maps">
                    <Icon id="pin" className="icon--green icon--medium block-link__icon" />
                    <address className="block-link__content">Perfect Pergolas Ltd,<br />PO Box 283,<br />Cirencester. GL7 9EY.</address>
                </a>
            </div>
            <div className="breathe">
                <a href="https://www.facebook.com/PerfectPergolas" rel="noreferrer" target="_blank" className="social-link" title="Connect with Perfect Pergolas on Facebook">
                    <Icon id="facebook" className="display--block" />
                </a>
                <a href="https://twitter.com/perfectpergolas" rel="noreferrer" target="_blank" className="social-link" title="Follow Perfect Pergolas on Twitter">
                    <Icon id="twitter" className="display--block" />
                </a>
                <a href="http://pinterest.com/perfectpergolas" rel="noreferrer" target="_blank" className="social-link" title="Follow Perfect Pergolas on Pinterest">
                    <Icon id="pinterest" className="display--block" />
                </a>
                <a href="https://www.linkedin.com/company/perfect-pergolas-ltd/" rel="noreferrer" target="_blank" className="social-link" title="Connect with Perfect Pergolas on LinkedIn">
                    <Icon id="linkedin" className="display--block" />
                </a>
                <a href="https://www.youtube.com/channel/UCrB0VZTeES7ieQGKyP2iILA" rel="noreferrer" target="_blank" className="social-link" title="Subscribe to the Perfect Pergolas Youtube channel">
                    <Icon id="youtube" className="display--block" />
                </a>
            </div>
        </div>
        <div className="cell medium-6 flow--medium">
            <QuoteBlock />
            <hr className="sep sep--light" />
            <h3 className="text-subheading text-align--center">We win awards too!</h3>
            <div className="container container-small awards">
                <img src="/img/awards/sbs.jpg" alt="Theo Paphitis | #SBS Small business Sunday winner" />
                <img src="/img/awards/scoot.jpg" alt="Scoot headline award | Winner 2014" />
                <img src="/img/awards/best-of-houzz.jpg" alt="Best of Houzz service 2015" />
            </div>
        </div>
    </div>
);

export default ContactCallout;
