import React, { useState } from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';
import LogoNav from './_ui/LogoNav';

const Navigation = ({ invertNav }) => {
    const [navIsOpen, setNavIsOpen] = useState(false);

    const handleOpenNav = () => {
        if (navIsOpen) {
            setNavIsOpen(false);
        } else {
            setNavIsOpen(true);
        }
    };

    const manualIsActive = (pathArr) => {
        if (typeof window !== 'undefined') {
            let pathSplit = window.location.pathname.split('/');

            pathSplit = pathSplit.filter((item) => item !== '');

            if (pathArr.includes(pathSplit[0])) {
                return '_is-active';
            }
        }

        return '';
    };

    return (
        <nav className={`nav ${(invertNav) ? 'nav--invert' : ''}`}>
            <div className="nav__container container">
                <button type="button" className={`nav-trigger ${(navIsOpen) ? '_is-open' : ''}`} onClick={handleOpenNav}>
                    <span>Menu</span>
                    <svg viewBox="0 0 48 48" width="48" height="48" className="icon nav-trigger__icon">
                        <rect className="ni-bar" id="ni-bar_1" x="21" y="6" width="27" height="6" rx="3" />
                        <rect className="ni-bar" id="ni-bar_2" x="0" y="21" width="48" height="6" rx="3" />
                        <rect className="ni-bar" id="ni-bar_3" x="12" y="36" width="36" height="6" rx="3" />
                    </svg>
                </button>
                <Link to="/" className="nav__logo" title="Home">
                    <LogoNav />
                </Link>
                <Link to="/" className={`nav__logo nav__logo--standin ${(navIsOpen) ? '_is-open' : ''}`} aria-hidden="true" title="Home">
                    <LogoNav />
                </Link>
                <ul className={`nav-list ${(navIsOpen) ? '_is-open' : ''}`}>
                    <li className="nav-list__item _has-subnav">
                        <Link to="/shop/" className={`nav-list__link text-nav-link ${manualIsActive(['shop', 'planner', 'customer-showcase'])}`} title="Browse modular pergolas" onClick={(e) => e.preventDefault()}>
                            <span>Pergolas</span>
                            <Icon id="chevron-down" className="nav-list__icon" />
                        </Link>
                        <ul className="nav-sublist">
                            <li className="nav-sublist__item">
                                <Link to="/shop/" activeClassName="_is-active" partiallyActive className="nav-sublist__link text-subnav-link" title="Browse modular pergolas">Modular Pergolas</Link>
                            </li>
                            <li className="nav-sublist__item">
                                <Link to="/planner/" activeClassName="_is-active" className="nav-sublist__link text-subnav-link" title="Design your perfect pergola">Bespoke Designs</Link>
                            </li>
                            <li className="nav-sublist__item">
                                <Link to="/customer-showcase/" activeClassName="_is-active" className="nav-sublist__link text-subnav-link" title="View customer pergolas and testimonials">Customer Showcase</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-list__item _has-subnav">
                        <Link to="/pergola-assembly/how-it-works/" className={`nav-list__link text-nav-link ${manualIsActive(['pergola-assembly', 'arbour-assembly'])}`} title="View how to assemble a pergola" onClick={(e) => e.preventDefault()}>
                            <span>Assembly</span>
                            <Icon id="chevron-down" className="nav-list__icon" />
                        </Link>
                        <ul className="nav-sublist">
                            <li className="nav-sublist__item">
                                <Link to="/pergola-assembly/how-it-works/" activeClassName="_is-active" className="nav-sublist__link text-subnav-link" title="View how to assemble a lean-to pergola">Lean-to Assembly</Link>
                            </li>
                            <li className="nav-sublist__item">
                                <Link to="/arbour-assembly/how-it-works-arbour/" activeClassName="_is-active" className="nav-sublist__link text-subnav-link" title="View how to assemble a stand-alone pergola">Stand-alone Assembly</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-list__item">
                        <Link to="/pergola-planning/general-information-about-our-pergolas/" className={`nav-list__link text-nav-link ${manualIsActive(['pergola-planning'])}`} title="View information about pergola"><span>Pergola information</span></Link>
                    </li>
                    <li className="nav-list__item">
                        <Link to="/news/" className={`nav-list__link text-nav-link ${manualIsActive(['news'])}`} title="View Perfect Pergola news"><span>Outdoor living</span></Link>
                    </li>
                    <li className="nav-list__item">
                        <Link to="/about/" className={`nav-list__link text-nav-link ${manualIsActive(['about'])}`} title="View information about perfectpergolas.co.uk"><span>About</span></Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;
