import React from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';

const BlogCard = ({
    title, imgSrc, imgAlt, postedAt, url
}) => (
    <div className="blog-card">
        {
            (imgSrc)
                ? <img src={imgSrc} className="blog-card__image" alt={imgAlt} />
                : null
        }
        <div className="card flow">
            <div>
                <span className="text-metadata">{postedAt}</span>
                <h4 className="text-subheading">{title}</h4>
            </div>
            <div className="blog-card__link">
                <Link to={url} className="more-link card__hitbox" title={`Read '${title}'`}>
                    <span>Read more</span>
                    <Icon id="arrow-right" className="icon--green" />
                </Link>
            </div>
        </div>
    </div>
);

export default BlogCard;
