import React from 'react';

import Icon from '@components/_ui/Icon';

const Alert = ({ type, children }) => (
    <div className={`alert alert--${type}`}>
        <Icon id="warning" className="alert__icon" />
        <div className="alert__content">
            {children}
        </div>
    </div>
);

Alert.defaultProps = {

};

export default Alert;
