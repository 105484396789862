import React, { useState } from 'react';

import { Link } from 'gatsby';

import '@src/scss/styles.scss';

import TableOfContents from '@components/_ui/TableOfContents';
import Icon from '@components/_ui/Icon';
import Layout from './Layout';

const InformationLayout = ({ pagesList, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleIsOpen = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    const generateRelativeLink = (direction) => {
        if (typeof window !== 'undefined') {
            let index = null;

            pagesList.forEach((page, key) => {
                if (page.href === window.location.pathname) {
                    index = key;
                }
            });

            if (direction === 'next') {
                index += 1;
            } else {
                index -= 1;
            }

            if (index > (pagesList.length - 1)) {
                index = 0;
            }

            if (index < 0) {
                index = pagesList.length - 1;
            }

            return pagesList[index].href;
        }
        return '';
    };

    return (
        <Layout
            mainClassName="wrapper-top--large wrapper-bottom--medium overflow--hidden"
        >
            <section className="container bracket">
                <TableOfContents pagesList={pagesList} />
                <div className="bracket__flex has-toc flow--large">
                    <div className="toc-controls">
                        <div className="breathe">
                            <Link to={generateRelativeLink('prev')} title="Previous page" className="toc-controls__button button--white button--reverse">
                                <Icon id="chevron-left" className="button__icon icon--small" />
                                <span>Previous</span>
                            </Link>
                            <Link to={generateRelativeLink('next')} title="Next page" className="toc-controls__button button--white">
                                <span>Next</span>
                                <Icon id="chevron-right" className="button__icon icon--small" />
                            </Link>
                        </div>
                        <a href="#toc-open" title="Open table of contents" className="toc-controls__button toc-controls__toggle button--white" onClick={handleToggleIsOpen}>
                            <span>Contents</span>
                        </a>
                    </div>
                    <div className="wysiwyg">
                        {children}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

InformationLayout.defaultProps = {
    pagesList: [],
    children: null
};

export default InformationLayout;
