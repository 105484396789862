import React from 'react';

import Icon from '@components/_ui/Icon';

const DownloadCard = ({ filename, path }) => (
    <a href={path} className="download bracket" title={`Download ${filename}`} rel="noreferrer" target="_blank">
        <Icon id="download" className="bracket__fixed icon--cloudy-sky icon--medium" />
        <span className="text-link bracket__flex">{ filename }</span>
    </a>
);

DownloadCard.defaultProps = {
    filename: 'Example PDF',
    path: '/downloads/example.pdf'
};

export default DownloadCard;
