import React from 'react';

import DownloadCard from '@components/_ui/DownloadCard';

const Downloads = ({ files }) => (
    <div>
        <div className="grid-x grid-margin-x grid-margin-y">
            {files && files.map((file) => (
                <div className="cell large-6" key={file.path}>
                    <DownloadCard filename={file.filename} path={file.path} />
                </div>
            ))}
        </div>
    </div>
);

export default Downloads;
