import React from 'react';

const Badge = ({
    authorAvatar, authorAvatarAlt, authorName, authorPosition
}) => (
    <div className="badge">
        <img src={authorAvatar} alt={authorAvatarAlt} className="avatar" />
        <div className="badge__content">
            <span className="text-subheading">{authorName}</span><br />
            <span className="text-metadata">{authorPosition}</span>
        </div>
    </div>
);

Badge.defaultProps = {
    authorAvatar: '/img/ade-avatar.jpg',
    authorAvatarAlt: 'Managing director Adrian Valentine, leaning against a pergola post',
    authorName: 'Adrian Valentine',
    authorPosition: 'Managing Director',
};

export default Badge;
