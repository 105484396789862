import React from 'react';

const ConversionTable = () => (
    <div className="responsive-table">
        <table className="table">
            <thead>
                <tr>
                    <th>To convert:</th>
                    <th>Into:</th>
                    <th>Multiply by:</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Inches</td>
                    <td>Millimetres</td>
                    <td>25.4</td>
                </tr>
                <tr>
                    <td>Millimetres</td>
                    <td>Inches</td>
                    <td>0.03937</td>
                </tr>
                <tr>
                    <td>Inches</td>
                    <td>Centimetres</td>
                    <td>2.54</td>
                </tr>
                <tr>
                    <td>Centimetres</td>
                    <td>Inches</td>
                    <td>0.3937</td>
                </tr>
                <tr>
                    <td>Feet</td>
                    <td>Metres</td>
                    <td>0.3048</td>
                </tr>
                <tr>
                    <td>Metres</td>
                    <td>Feet</td>
                    <td>3.281</td>
                </tr>
            </tbody>
        </table>
    </div>
);

export default ConversionTable;
