import React from 'react';

const InlineImageGallery = ({ images }) => (
    <div>
        <div className="grid-x grid-margin-x grid-margin-y inline-gallery">
            {images.map((image) => (
                <div className="cell medium-6 large-3 inline-gallery__item" key={image.src}>
                    <img src={image.src} alt={image.alt} />
                </div>
            ))}
        </div>
    </div>
);

InlineImageGallery.defaultProps = {
    images: []
};

export default InlineImageGallery;
